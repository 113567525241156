import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as global from '../services/global.service';

import { User } from '../models/user';
import { Router } from '@angular/router';
import { Customer } from '../models/customer';
import { ApicallsService } from './apicalls.service';
import { Events } from './events.service';
import { Admin } from '../models/admin';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private currentCustomerSubject: BehaviorSubject<Customer>;
    public currentCustomer: Observable<Customer>;
    private currentAdminSubject: BehaviorSubject<Admin>;
    public currentAdmin: Observable<Admin>;

    constructor(private http: HttpClient, private router: Router, private api: ApicallsService, private event: Events) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUserMn4Admin')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentCustomerSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('currentCustomerunBlockAdmin')));
        this.currentCustomer = this.currentCustomerSubject.asObservable();
        this.currentAdminSubject = new BehaviorSubject<Admin>(JSON.parse(localStorage.getItem('currentAdminunBlockAdmin')));
        this.currentAdmin = this.currentAdminSubject.asObservable();
    }

    isAuthenticated(val) {
        if (val !== '' && val != null) {
          if (val.split('.').length === 3) {
            try {
              const base64Url = val.split('.')[1];
              const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
              const exp = JSON.parse(window.atob(base64)).exp;
              // JWT with an optonal expiration claims
              if (exp) {
                const isExpired = Math.round(new Date().getTime() / 1000) >= exp;
                if (isExpired) {
                  // FAIL: Expired token
                  return false;
                } else {
                  // PASS: Non-expired token
                  return true;
                }
              }
            } catch (e) {
              // PASS: Non-JWT token that looks like JWT
              return true;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get currentCustomerValue(): Customer {
      return this.currentCustomerSubject.value;
    }

    public get currentAdminValue(): Admin {
      return this.currentAdminSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(global.API_URL + '/api/auth/login', { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                   if (user.user.change_password === 0) {
                    localStorage.setItem('currentUserMn4Admin', JSON.stringify(user.user));
                    this.currentUserSubject.next(user.user);
                   } else {
                    this.currentUserSubject.next(user.user);
                   }
                }

                return user;
            }));
    }

    updateLogin(user) {
      user.access_token = this.currentUserSubject.value.access_token;
      this.currentUserSubject.next(user);
      localStorage.setItem('currentUserMn4Admin', JSON.stringify(user));
    }

    updateUser(user) {
      user.access_token = this.currentUserSubject.value.access_token;
      this.currentUserSubject.next(user);
      localStorage.setItem('currentUserMn4Admin', JSON.stringify(user));
    }

    updateCustomer(customer) {
      this.currentCustomerSubject.next(customer);
      localStorage.setItem('currentCustomerunBlockAdmin', JSON.stringify(customer));
    }

    updateAdmin(admin) {
      this.currentAdminSubject.next(admin);
      localStorage.setItem('currentAdminunBlockAdmin', JSON.stringify(admin));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUserMn4Admin');
        localStorage.removeItem('currentCustomerunBlockAdmin');
        localStorage.removeItem('currentAdminunBlockAdmin');
        localStorage.removeItem('wizard');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }
}
