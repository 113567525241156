import { Component, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../../services/apicalls.service';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { Events } from '../../services/events.service';
import toastr from 'toastr';

import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {
  name: string;
  user: User;
  showPassword: false;
  showConfirm: false;
  levelPass = 0;
  emailError = false;
  nameError = false;
  wizard_run = true;
  pasError = false;
  updateProcess = false;
  inputTypePassword: string;
  inputTypeConfirm: string;
  step_wizard: any;
  private modalRef: NgbModalRef;
  private modalConf: NgbModalRef;
  formEditUser: FormGroup;
  formCustomers: FormGroup;
  formType: FormGroup;
  public config: PerfectScrollbarConfigInterface = {};
  accounttype = '';
  total_qr = 2;
  total_app = 2;
  confirm_title = '';
  confirm_text = '';

  constructor(private formBuilder: FormBuilder,
              private api: ApicallsService,
              private modalService: NgbModal,
              private auth: AuthenticationService,
              private cdRef: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private currentroute: ActivatedRoute,
              private event: Events) {}

  ngOnInit() {
    if (this.auth.currentCustomerValue) {
      if (this.auth.currentCustomerValue.tipo === 1) {
        this.accounttype = 'BASIC';
      }
    } else {
      this.accounttype = 'BASIC';
    }
    if (this.auth.currentUserValue.cupon) {
      this.total_app = this.auth.currentUserValue.cupon.num_app;
      this.total_qr = this.auth.currentUserValue.cupon.num_qr;
    }
    this.formEditUser = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [ Validators.required, Validators.email]],
        password: [''],
        confirm: ['']
    });
    this.formCustomers = this.formBuilder.group({
      nombre: ['', Validators.required ],
        direccion: ['', Validators.required],
        ciudad: ['', Validators.required],
        telefono: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        contacto: [''],
        cif: [''],
    });
    this.formType = this.formBuilder.group({
      codigo: [''],
    });
    this.inputTypePassword = 'password';
    this.inputTypeConfirm = 'password';
    this.step_wizard = JSON.parse(localStorage.getItem('wizard'));
    if (this.step_wizard > 0) {
      this.wizard_run = true;
    } else {
      this.wizard_run = false;
    }
    this.event.subscribe('finalwizard', () => {
      this.wizard_run = false;
    });
    this.event.subscribe('startwizard', () => {
      this.wizard_run = true;
    });
    if(this.auth.currentCustomerValue){
      this.formCustomers.patchValue(this.auth.currentCustomerValue);
    }
    
  }

  ngAfterViewInit() {
    this.user = this.auth.currentUserValue;
    this.formEditUser.patchValue(this.user);
    this.formEditUser.patchValue({password: ''});
    this.cdRef.detectChanges();
    const set = function() {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      const topOffset = 0;
      if (width < 1170) {
        $('#main-wrapper').addClass('mini-sidebar');
      } else {
        $('#main-wrapper').removeClass('mini-sidebar');
      }
    };
    $(window).ready(set);
    $(window).on('resize', set);

    $('.search-box a, .search-box .app-search .srh-btn').on(
      'click',
      function() {
        $('.app-search').toggle(200);
      }
    );
    $('#sidebarnav li a:not(.has_child)').on(
      'click',
      function() {
       $('.left-sidebar').removeClass('visible');
      }
    );
    $('body').trigger('resize');
  }

  clickMenu($event) {
    if ($('.left-sidebar').hasClass('visible')) {
      $('.left-sidebar').removeClass('visible');
    } else {
      $('.left-sidebar').addClass('visible');
    }
    event.stopPropagation();
  }

  closeMenu() {
    if ($('.left-sidebar').hasClass('visible')) {
      $('.left-sidebar').removeClass('visible');
    }
  }

  logout() {
    this.auth.logout();
  }

  open(content) {
    this.formEditUser.patchValue({password: ''});
    this.modalRef = this.modalService.open(content, {centered: true, windowClass: 'newModal', backdrop : 'static', keyboard : false});
  }

  clearContent(input: string) {
    this.formEditUser.get(input).reset();
  }

  abrirModalConfirm(modal) {
    this.modalConf =  this.modalService.open(modal,
                      {centered: true, windowClass: 'newModal', backdrop : 'static', keyboard : false});
  }

  cerrarModalConfirm() {
    this.modalConf.close();
  }

  showPasswordToggle(info: any) {
    this.showPassword = info;
    if (this.showPassword) {
      this.inputTypePassword = 'text';
    } else {
      this.inputTypePassword = 'password';
    }
  }
  showConfirmToggle(info: any) {
    this.showConfirm = info;
    if (this.showConfirm) {
      this.inputTypeConfirm = 'text';
    } else {
      this.inputTypeConfirm = 'password';
    }
  }

  onInputPass(value) {
    let cont = 0;
    if (value.length === 0) {
        this.levelPass = 0;
        return;
    }

    if (value.length > 7) {
        cont ++;
    }

    const matches = value.match(/\d+/g);
    if (matches != null) {
        cont ++;
    }

    if (/[A-Z]/.test(value)) {
        cont ++;
    }

    if (cont === 0) {
        cont++;
    }
    this.levelPass = cont;
  }

}

