import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modalconfirm',
  templateUrl: './modalconfirm.component.html'
})
export class ModalConfirmComponent implements OnInit {

  @Input() layout;
  pageInfo;
  private modalRef: NgbModalRef;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private titleService: Title
    
  ) {}
  ngOnInit() {}
  openConfirm(content){
    this.modalRef = this.modalService.open(content, {centered: true, windowClass: 'confirmModal',  keyboard  : false, backdrop: 'static'});
  }
}
