import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  formRegister: FormGroup;
  errorname = false;
  errortutor = false;
  errormail = false;
  errortelefono = false;
  erroredad = false;
  forminvalid = false;
  edad: number = 0;
  turnoViernes = false;
  turnoSabado = false;
  showTurnoErrorModal = false;
  showErrorModal = false;
  showSuccessModal = false;
  messageErrorModal = '';
  sending = false;
  dia = '';
  edades = [
    {
      id: 4,
      numero: 4,
      selected: false
    },
    {
      id: 5,
      numero: 5,
      selected: false
    },
    {
      id: 6,
      numero: 6,
      selected: false
    },
    {
      id: 7,
      numero: 7,
      selected: false
    },
    {
      id: 8,
      numero: 8,
      selected: false
    },
    {
      id: 9,
      numero: 9,
      selected: false
    },
    {
      id: 10,
      numero: 10,
      selected: false
    },
    {
      id: 11,
      numero: 11,
      selected: false
    },
    {
      id: 12,
      numero: 12,
      selected: false
    }
  ];
  viewForm: boolean;
  finished: boolean = false;
  started: boolean = false;


  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private api: ApicallsService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authenticationService: AuthenticationService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.viewForm = false;
    this.formRegister = this.formBuilder.group({
        nombre: ['', Validators.required],
        nombretutor: ['', Validators.required],
        telefono: ['', Validators.required],
        email: ['', [ Validators.required, Validators.email]],
        edad: [''],
        accept: [null]
    });
    this.finished = false;
    this.started = true;
    const limite = moment('2024-09-14 17:00:00');
    if(moment().isAfter(limite)){
      this.finished = true;
    }
    const start = moment('2024-09-02').startOf('day');
    if(moment().startOf('day').isBefore(start)){
      this.started = true;
    }

  }

  selectDia(dia) {
    const limite = moment('2024-09-13 17:00:00');
    if(moment().isAfter(limite) && dia === 1){
      this.messageErrorModal = 'Las inscripciones para el Viernes 13 de septiembre ya estan cerradas. Prueba el sábado 14.';
      this.showErrorModal = true;
      return;
    }
    if (dia === 1) {
      this.turnoViernes = true;
      this.turnoSabado = false;
    } else {
      this.turnoViernes = false;
      this.turnoSabado = true;
    }
    this.viewForm = true;
  }

  send(formValue) {
    if (!this.sending) {
      this.sending = true;
      this.forminvalid = true;
      this.errorname = false;
      this.errortelefono = false;
      this.errortutor = false;
      this.errormail = false;
      this.erroredad = false;
      let error = false;
      if (formValue.nombre === '') {
        this.errorname = true;
      }
      if (formValue.nombretutor === '') {
        this.errortutor = true;
      }
      if (formValue.telefono === '') {
        this.errortelefono = true;
      }
      if (formValue.email === '' || this.formRegister.invalid) {
        this.errormail = true;
      }

      if (formValue.edad === '' ) {
        this.erroredad = true;
      }
      if (!this.turnoViernes && !this.turnoSabado) {
        error = true;
      }

      if (isNaN(formValue.edad)) {
        this.messageErrorModal = 'La edad debe de ser numérica';
        this.showErrorModal = true;
        this.erroredad = true;
        this.sending = false;
        this.forminvalid = false;
        return;
      }

      if (+formValue.edad < 4) {
        this.messageErrorModal = 'La edad mínima para inscribirse es de 4 años';
        this.showErrorModal = true;
        this.erroredad = true;
        this.sending = false;
        this.forminvalid = false;
        return;
      }

      if (+formValue.edad > 14) {
        this.messageErrorModal = 'La edad máxima para inscribirse es de 14 años';
        this.showErrorModal = true;
        this.erroredad = true;
        this.sending = false;
        this.forminvalid = false;
        return;
      }

      if (formValue.accept !== true) {
        this.messageErrorModal = 'Se deben de aceptar las bases legales.';
        this.showErrorModal = true;
        this.sending = false;
        this.forminvalid = false;
        return;
      }

      if (!this.erroredad && !this.errorname && !this.errortutor && !this.errortelefono && !this.errormail && !error) {
        if (this.turnoViernes) {
          formValue.dia = '13 de Septiembre';
          this.dia = '13';
        } else {
          formValue.dia = '14 de Septiembre';
          this.dia = '14';
        }
        this.api.newReserva(formValue).subscribe((data: any) => {
          if (data.error) {
            // Mostrar error que viene en data.message
            this.messageErrorModal = data.error;
            this.showErrorModal = true;
            this.sending = false;
          } else {
            this.showSuccessModal = true;
            this.turnoSabado = false;
            this.turnoViernes = false;
            this.formRegister.reset();
            this.sending = false;
            for (let i = 0; i < this.edades.length; i++) {
              this.edades[i].selected = false;
            }
          }
        }, error => {
          this.messageErrorModal = 'Se ha producido un error en la inscripción. Por favor inténtelo de nuevo';
          this.showErrorModal = true;
          this.sending = false;
        });
      } else {
        this.sending = false;
        if (error) {
          this.showTurnoErrorModal = true;
        }
      }
      this.forminvalid = false;
    }
  }

  closeModals () {
    this.showErrorModal = false;
    this.showTurnoErrorModal = false;
    this.showSuccessModal = false;
  }

}
