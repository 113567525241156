import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticationService } from '../../services/authentication.service';
import { Events } from '../../services/events.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'blue';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;
  showRtl = false;
  year: any;
  wizard_run = true;
  step_wizard: any;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router, public auth: AuthenticationService, private event: Events) {
    
  }

  ngOnInit() {
    this.year = new Date().getFullYear();

    this.step_wizard = JSON.parse(localStorage.getItem('wizard'));
    if (this.step_wizard > 0) {
      this.wizard_run = true;
    } else {
      this.wizard_run = false;
    }
    this.event.subscribe('finalwizard', () => {
      this.wizard_run = false;
    });
    this.event.subscribe('startwizard', () => {
      this.wizard_run = true;
    });
  }

  closeMenu() {
    /*if ($('.left-sidebar').hasClass('visible')) {
      $('.left-sidebar').removeClass('visible')
    }*/
  }
}
