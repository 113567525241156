import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  environments = [
      { id: 1, value: "Foster’s Hollywood Artes Gráficas" },
      { id: 2, value: "Foster’s Hollywood Saler" },
      { id: 3, value: "Foster’s Hollywood Bonaire" }
  ];
  @Input() layout;
  pageInfo;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
    
  ) {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe(event => {
        if (event.title === 'Tickets') {
          this.activatedRoute.paramMap.subscribe( params => {
            if (params.get('validated') === '0') {
              this.titleService.setTitle(event['title'] + ' Sin Validar');
              event.urls[0].title = 'Tickets Sin Validar';
            } else {
              this.titleService.setTitle(event['title'] + ' Validados');
              event.urls[0].title = 'Tickets Validados';
            }
            this.pageInfo = event;
          });
        } else {
          this.titleService.setTitle(event['title']);
          this.pageInfo = event;
        }
      });
  }
  ngOnInit() {}
  
}
