import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { StadisticsComponent } from './stadistics/stadistics.component';
import { AuthGuard } from './guards/auth.guard';
import { DeleteComponent } from './delete/delete.component';
import { HomeTestComponent } from './home-test/home-test.component';

export const routes: Routes = [
  {
    path: 'admin',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [{
        path: 'reservas',
        loadChildren: './stadistics/stadistics.module#StadisticsModule'
      }
    ]
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'delete/:code',
    component: DeleteComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'test',
    component: HomeTestComponent,
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule {}
