import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { first } from 'rxjs/operators';

import swal from 'sweetalert2';
import toastr from 'toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  year: any;
  formLogin: FormGroup;
  pasError = false; 
  levelPass = 0;
  formForgot: FormGroup;
  formNewPassword: FormGroup;
  loading = false;
  submitted = false;
  showForgot = false;
  newUser = false;
  idUser: any;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private api: ApicallsService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private authenticationService: AuthenticationService,
              private alertService: AlertService) {

              if (this.authenticationService.currentUserValue) {
                  this.router.navigate(['/admin/reservas']);
              }
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.formLogin = this.formBuilder.group({
        username: ['', [ Validators.required, Validators.email]],
        password: ['', Validators.required]
    });

    this.formForgot = this.formBuilder.group({
      username: ['', [ Validators.required, Validators.email]],
    });
    this.formNewPassword = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  forgot() {
    this.showForgot = true;
  }

  login(formValue: any) {
    this.spinner.show();
    this.authenticationService.login( formValue.username , formValue.password )
      .pipe(first())
      .subscribe( data => {
          this.spinner.hide();
          if (data.user.change_password === 1) {
            swal.fire('Primera visita',
            'Por motivos de seguridad, en tu primera visita al panel, debes actualizar la contraseña', 'warning');
            this.newUser = true;
            this.idUser = data.user.id;
          } else {
            this.router.navigate(['/admin/reservas']);
          }
        },
        error => {
          this.spinner.hide();
          swal.fire('Login', 'No tienes permiso para entrar a la aplicación', 'error');
        }
      );
  }

  backToLogin() {
    this.showForgot = false;
  }

  onInputPass(value) {
    let cont = 0;
    if (value.length === 0) {
        this.levelPass = 0;
        return;
    }

    if (value.length > 7) {
        cont ++;
    }

    const matches = value.match(/\d+/g);
    if (matches != null) {
        cont ++;
    }

    if (/[A-Z]/.test(value)) {
        cont ++;
    }

    if (cont === 0) {
        cont++;
    }
    this.levelPass = cont;
}

}
