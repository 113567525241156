import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApicallsService } from '../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert.service';
import { first } from 'rxjs/operators';

import swal from 'sweetalert2';
import toastr from 'toastr';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  pasError = false;
  levelPass = 0;
  formNewPassword: FormGroup;
  loading = false;
  submitted = false;
  idUser: any;
  returnUrl: string;
  key = '';

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private api: ApicallsService,
              private router: Router,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.formNewPassword = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.key = this.route.snapshot.queryParams['key'];
  }

  changePassword(formValue: any) {
    this.pasError = false;
    if (this.levelPass < 3) {
      swal.fire('Cambiar Contraseña', 'La contraseña debe tener 1 letra mayúscula, 1 número y una longitud mínima de 8', 'error');
    } else {
      if (this.key === '' || this.key === null) {
        swal.fire('Cambiar Contraseña', 'La url actual no es correcta para realizar el cambio de contraseña.', 'error');
        return;
      }
      if (formValue.password !== '' && formValue.confirm !== '' && this.levelPass === 3) {
        if (formValue.password !== formValue.confirm) {
          this.pasError = true;
        } else {
          this.pasError = false;
          this.api.changePassword(formValue, this.idUser).subscribe( data => {
            toastr.success(null, 'Contraseña cambiada correctamente', {positionClass: 'toast-top-center'});
            this.formNewPassword.reset();
            this.router.navigate(['login']);
          }, error => {
            swal.fire('Cambiar Contraseña', 'No se ha cambiado la contraseña, vuelva a intentarlo', 'error');
          });
      }
      }
    }
  }

  onInputPass(value) {
    let cont = 0;
    if (value.length === 0) {
        this.levelPass = 0;
        return;
    }

    if (value.length > 7) {
        cont ++;
    }

    const matches = value.match(/\d+/g);
    if (matches != null) {
        cont ++;
    }

    if (/[A-Z]/.test(value)) {
        cont ++;
    }

    if (cont === 0) {
        cont++;
    }
    this.levelPass = cont;
}

}
